<template>
  <section class="form-admin px-4" v-allow:auth="'form.read'" >
    <sub-header
      :showBtn="checkPermission('form.create')"
      :handleClick="showModal"
      buttonText="Create Form"
    />
    <table-large
      id="formAdmin"
      class=""
      :columns="columnOption"
      :rows="rows"
      :key="totalRows"
      :total-rows="totalRows"
      @onButtonClick="onPreview($event)"
      :isLoading="loadingStates.form"
      @on-edit="onEdit($event)"
      :pagination-options="paginationOptions"
      :filters="serverParams"
      :sort-options="{
        enabled: true,
      }"
      sortingEnabled
      @sort-change="onSortChanged"
      :filterOptions="columnFilterOptions"
      :filterOptionsAsync="columnFilterOptionsAsync"
      @page-change="onPageChange($event)"
      @column-filter="onColumnFilter"
      @per-page-change="onPageChange($event, true)"
      @filters-clear="clearFilters"
      @search-filter="getSearchOptionsTick($event)"
      :config="{sortingOption: {enabled: true}}"
    >
      <template #link="{ customData }">
        <div class="truncate max-w-full whitespace-nowrap cursor-pointer">
          <span
            @click="onClick(customData.id)"
            v-tippy 
            :content="customData.name"
            >{{ customData.name }}
          </span>
        </div>
      </template>
      <template #source="{ customData }">
        <span
          @click="onPackageClick(customData)"
          :class="
            customData.package_count > 0
              ? 'text-primary cursor-pointer hover:text-primary-focus hover:underline'
              : 'text-gray-500'
          "
          >used in {{ customData.package_count }} packages</span
        >
      </template>
    </table-large>

    <modal-content
      ref="form-modal"
      :max-width="480"
      name="form-modal"
      title="Add New Form"
      @close="closeModal"
      :showFooter="false"
    >
      <template #content>
        <FormulateForm #default="{ hasErrors }" class="min-h-full flex-1">
          <div class="flex flex-col justify-between min-h-full">
            <div class="form flex w-full flex-col flex-1">
              <FormulateInput
                class="w-full"
                label="Form Name"
                placeholder="Form Name"
                type="text"
                validation="required"
                :element-class="
                  (context, classes) => ['flex-1 min-w-full'].concat(classes)
                "
                v-model="formData.name"
              />
              <FormulateInput
                  v-model="formData.entity_types"
                  validation="required"
                  class="w-full"
                  label="Entity Type"
                  placeholder="Select Entity Type"
                  type="multi-select"
                  variant="alt"
                  :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                  :options="entityTypeOptions"
                  :loading="loadingStates.entityTypeOptions"
                  :config="ENTITY_TYPES_DROPDOWN_CONFIG"
              />
              <FormulateInput
                class="w-full"
                label="Form Description"
                placeholder="Form Description"
                type="textarea"
                validation="required"
                :element-class="
                  (context, classes) => ['flex-1 min-w-full'].concat(classes)
                "
                v-model="formData.description"
              />
            </div>
            <div class="card-actions justify-end">
              <Button
                :loader="createFormLoading"
                text="Create Form"
                type="primary"
                :disabled="hasErrors || createFormLoading"
                @click="handleCreateForm()"
              />
            </div>
          </div>
        </FormulateForm>
      </template>
      <template #footer>
        <div></div>
      </template>
    </modal-content>

    <modal-content
      ref="packages-list-modal"
      :max-width="480"
      height="60%"
      name="packages-list-modal"
      :showFooter="false"
      :title="packageTitle"
    >
      <template #content>
        <div class="relative flex-1 flex flex-col max-h-full overflow-hidden">
          <div class="relative w-full p-2">
            <div
              class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
            >
              <svg
                aria-hidden="true"
                class="w-5 h-5 text-gray-500"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              type="text"
              id="simple-search"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-1.5"
              placeholder="Search"
              v-model="search"
            />
          </div>
          <div class="px-2 flex overflow-auto scroll-bar">
            <ul class="flex flex-col w-full">
              <template v-if="loadingStates.packages">
                <li
                  class="flex items-center p-8 font-medium bg-card-bg white border text-gray-800"
                >
                  <Loader class="m-auto" />
                </li>
              </template>
              <template v-else-if="filterPackageList.length > 0">
                <li
                  v-for="item in filterPackageList"
                  :key="item.package_id"
                  class="flex items-center py-3 px-4 font-medium bg-card-bg white border text-gray-800"
                >
                  <router-link
                    class="flex justify-between w-full cursor-pointer text-primary hover:underline hover:opacity-80"
                    :to="`/packages/${item.package_id}/form-editor`"
                    >{{ item.package_name }}</router-link
                  >
                </li>
              </template>
              <template v-else>
                <li
                  class="flex items-center p-8 font-medium bg-card-bg white border text-gray-800"
                >
                  <span class="m-auto">NO PACKAGES FOUND!</span>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </template>
    </modal-content>
  </section>
</template>

<script>
import tableLarge from "@shared/dashboard-components/table-large";
import SubHeader from "@/components/SubHeader";
import Button from "@/components/button";
import modalContent from "@shared/modal-content";
import axios from "@/axios";
import { mapActions, mapGetters, mapState } from "vuex";
import { onlyUnique } from "@/plugins/utils.js";
import { uuid } from "vue-uuid";
const Loader = () => import("@shared/loader");
import { checkPermission } from "@shared/utils/functions"
import { ENTITY_TYPES_DROPDOWN_CONFIG } from "@shared/utils/constants";

export default {
  name: "Form-Admin",
  components: {
    tableLarge,
    SubHeader,
    Button,
    modalContent,
    Loader
  },
  data() {
    return {
      ENTITY_TYPES_DROPDOWN_CONFIG,
      searchTimeout: 500,
      searchLoading: {},
      paginationOptions: {
        enabled: true,
        mode: "remote",
        perPage: 10
      },
      columns: [
        {
          label: "Name",
          field: "name",
          query_key: "form_name",
          config: {
            filter: true,
            type: "link",
            title: true
          },
          tdClass: 'truncate max-w-xs'
        },
        {
          label: "Packages",
          field: "package_count",
          sortable: false,
          config: {
            filter:  false,
            type: 'source'
           
          },
          tdClass: 'truncate max-w-xs'
        },
        {
          label: "Description",
          field: "description",
          config: {
            filter: true,
            title: true
          },
          tdClass: 'truncate max-w-xs'
        },
        // {
        //     label: "Preview",
        //     field: "preview",
        //     config: {
        //         type: "button",
        //         buttonText: "Preview",
        //     },
        // },
        // {
        //     label: "Date",
        //     field: "createdAt",
        //     config: {
        //         filter: false,
        //     },
        // },
        // {
        //   label: "",
        //   field: "action",
        //   config: {
        //     filter: false,
        //     type: "action_edit",
        //   },
        // },
      ],
      rows: [],
      loadingStates: {
        form: false,
        packages: false,
        entityTypeOptions: false,
      },
      createFormLoading: false,
      formData: {
        name: "",
        description: "",
        entity_types: [],
      },
      params: {
        page: 1,
        count: 10,
      },
      totalRows: 0,
      search: "",
      packagesList: [],
      packageTitle: "",
      serverParams: null,
      sortParams: null,
      columnFilterOptionsAsync: {},
    };
  },
  title: "Forms",
  methods: {
    checkPermission,
    ...mapActions(["fetchFormRows", "fetchEntityTypeList"]),

    async fetchEntityTypeOptions() {
        this.loadingStates.entityTypeOptions = true;
        if (!this.entityTypeOptions.length) {
            await this.fetchEntityTypeList();
        }
        this.loadingStates.entityTypeOptions = false;
    },

    onPreview(row) {
      const routeData = this.$router.resolve({
        name: "AdditionalForm",
        params: { form_id: row.id },
      });
      window.open(routeData.href, "_blank");
    },

    // async fetchFormsData() {
    //     this.loadingStates.form = true;
    //     try {
    //         let qParams = new URLSearchParams(this.params);
    //         const { data } = await axios.get(`/tenant-form?${qParams}`);
            // await this.fetchFormRows();
    //         this.rows = data?.data.map((row) => ({ ...row, package_count: `used in ${row.package_count} packages` })) ?? [];
    //         this.totalRows = data?.pagination_info.total_rows;
            // this.rows = this.getFormRows || [];
    //     } catch (error) {
    //         console.log("[+] error while fetching tenant-form  :>> ", error);
    //     }
    //     this.loadingStates.form = false;
    // },

    async fetchFormsData() {
      this.loadingStates.form = true;
      try {
        let qParams = new URLSearchParams(this.params);
        let filterParams = {};

        if (this.serverParams) {
          Object.keys(this.serverParams).map((key) => {
            // if that key exist
            // add it to filter else we'll delete it
            // so it wont make empty strings in parameters
            filterParams[key] = this.serverParams[key]
              ? this.serverParams[key]["name"]
              : delete filterParams[key];
          });
        }
        const { data } = await axios.get(`/tenant-form?${qParams}`, {
          params: {
            ...filterParams,
            ...this.sortParams
          },
        });
        // await this.fetchFormRows();
        // this.rows = data?.data ?? [];
        this.rows = data?.data ?? [];

        this.totalRows = data?.pagination_info.total_rows;
        // this.rows = this.getFormRows || [];
      } catch (error) {
        console.log("[+] error while fetching tenant-form  :>> ", error);
      }
      this.loadingStates.form = false;
    },

    async showModal() {
      this.$refs["form-modal"].showModal();
      this.formData = {
        name: "",
        description: "",
      };
    },
    closeModal() {
      this.$refs["form-modal"].hideModal();
    },

    async onPageChange(data, resetPage = false) {
      if (resetPage) {
        this.params.page = 1;
      } else this.params.page = data.currentPage;
      this.params.count = data.currentPerPage;
      this.paginationOptions.perPage = data.currentPerPage;
      if(!resetPage) {
        await this.$nextTick();
        const scrollTarget = document.getElementById('formAdmin');
        if (scrollTarget) {
          scrollTarget.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      }
      await this.fetchFormsData();
    },

    async handleCreateForm() {
      this.createFormLoading = true;
      const url = `/tenant-form/form`;
      const payload = {
        description: this.formData.description,
        form_name: this.formData.name,
        entity_types: this.formData.entity_types.map((el) => ({ entity_type_id: el.id })),
      };
      try {
        const { data } = await axios.post(url, payload);

        this.$router.push({
          name: "form admin details",
          params: { id: data.check_id.id },
        });
        this.$toast.success(data.message);
        this.closeModal();
      } catch (error) {
        // this.createFormLoading = false
        this.$toast.warning(
          error.response.data.detail || "Failed to create new form"
        );
        this.closeModal();
      }
      this.createFormLoading = false;
    },
    onEdit({ row }) {
      this.$router.push({
        name: "form admin details",
        params: { id: row.id },
      });
    },
    onClick(id) {
      if (!(checkPermission('form.read'))) {
    return;
}
      this.$router.push({
        name: "form admin details",
        params: { id: id },
      });
    },

    // async onColumnFilter({ columnFilters }) {
    //   this.serverParams = columnFilters ? { ...columnFilters } : null;
    //   await this.fetchFormsData();
    // },
    async onColumnFilter({ columnFilters }) {
      this.params.page = 1;
      this.paginationOptions.perPage = this.params.count;
      this.serverParams = columnFilters ? { ...columnFilters } : null;
      await this.fetchFormsData();
    },

    async onSortChanged(data) {
      if (data && data.length && data[0].type !== 'none') {
        data = data[0]
        this.sortParams = {
          sort: data.field + ':' + data.type
        },
        await this.fetchFormsData()
      }else{
        this.sortParams = null
        await this.fetchFormsData()
      }
    },

    getFilterOptions(columns, rows) {
      return columns.reduce((result, curr) => {
        result[curr.field] = rows
          .filter((row) => row[curr.field])
          .map((row) => row[curr.field])
          .filter(onlyUnique)
          .map((el) => ({ id: uuid.v4(), name: el }));
        return result;
      }, {});
    },

    async clearFilters() {
      this.serverParams = null;
      await this.fetchFormsData();
    },
    async onPackageClick(params) {
      if (!(checkPermission('form.edit'))) {
    return;
}
      if (params.package_count <= 0) {
        return;
      }
      this.packageTitle = params.name;
      this.$refs["packages-list-modal"].showModal();
      this.loadingStates.packages = true;
      try {
        const { data } = await axios.get(`/tenant-form/${params.id}/packages`);
        this.packagesList = data?.data ?? [];
      } catch (error) {
        console.log(error, "[+] failed to fetch to packages");
      }
      this.loadingStates.packages = false;
    },
    handlePackageList(item) {
      console.log("item :>> ", item);
    },
    getSearchOptionsTick(payload) {
      if (this.searchTimeout) clearTimeout(this.searchTimeout);
      const delay = 500;
      this.searchTimeout = setTimeout(() => {
        this.getSearchOptions(payload);
      }, delay);
    },

    async getSearchOptions(payload) {
      if (!payload.value) this.columnFilterOptionsAsync = null;
      else {
        this.$set(this.searchLoading, payload.column.field, true);
        try {
          const requestData = {
            ...this.serverParams,
            [payload.column.query_key || payload.column.field]: payload.value,
          };
          const { data } = await axios.get(`/tenant-form`, {
            params: requestData,
          });
          console.log('data :>> ', data);
          this.columnFilterOptionsAsync = this.getFilterOptions(
            this.columns,
            data?.data
          );
        } catch (error) {
          console.log("getSearchOptions :>> ", error);
        }
        this.$set(this.searchLoading, payload.column.field, false);
      }
    },
  },
  computed: {
    ...mapState({
        entityTypeOptions: 'entityTypeList',
    }),
    ...mapGetters(["getFormRows"]),

    columnFilterOptions() {
      return this.getFilterOptions(this.columnOption, this.rows);
    },
    filterPackageList() {
      return this.packagesList.filter((pack) =>
        pack.package_name.includes(this.search)
      );
    },
    columnOption() {
      return this.columns.map((el) => ({
        ...el,
        config: {
          ...el.config,
          isLoading: !!this.searchLoading[el.field] || false,
        },
      }));
    },
  },
  async mounted() {
    await this.fetchFormsData();
    this.fetchEntityTypeOptions();
  },
};
</script>

<style lang="scss" scoped></style>
